const rootPath = '/app-edge';

export const urls = {
  applications: `${rootPath}/application-catalog`,
  addApplication: `${rootPath}/application-catalog/add-application`,
  editApplication: `${rootPath}/application-catalog/edit-application`,
  applicationGroups: `${rootPath}/application-catalog/application-groups`,
  addApplicationGroups: `${rootPath}/application-catalog/application-groups/add`,
  editApplicationGroups: `${rootPath}/application-catalog/application-groups/edit`,
  applicationDiscovery: `${rootPath}/application-catalog/application-discovery`,
  applicationSecurity: `${rootPath}/application-security`,
  attackAnalysis: `${rootPath}/application-security/attack-analysis`,
  policyManagement: `${rootPath}/application-security/policy-management`,
  bypassRules: `${rootPath}/application-security/policy-management/bypass-rules`,
  customRules: `${rootPath}/application-security/policy-management/custom-rules`,
  wafRules: `${rootPath}/application-security/policy-management/owasp-rules`,
  addRule: `${rootPath}/application-security/policy-management/add-rule`,
  editRule: `${rootPath}/application-security/policy-management/edit-rule`,
  confirmPolicyDeletion: `${rootPath}/application-security/policy-management/confirm-policy-deletion`,
  discovery: `${rootPath}/discovery`,
};
