/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from './file-code-solid.svg'
// import { ReactComponent as icon } from './file-code-solid.svg'

import { EyeIcon } from '@panwds/icons';
import { urls } from './routes/urls';
import { checkHasLicense, checkMfePSIfEnabled } from './utils/nav-helpers';

const appSecConfigNav = {
  navigation: [
    {
      key: 'app-edge',
      title: 'App Edge',
      path: '/app-edge',
      expandable: true,
      noRBACheck: true,
      exact: false,
      icon: EyeIcon, // either svg data url or panwds icon component, only needed in the first level
      mergeNav: true, // required if the first level is shared
      children: [
        {
          key: 'app-edge-applications',
          title: 'Applications',
          path: urls.applications,
          noRBACheck: true,
          // no component, specified in upper level
        },
        {
          key: 'app-edge-security',
          title: 'Security',
          path: urls.policyManagement,
          noRBACheck: true,
          // no component, specified in upper level
        },
      ],
      // in this example, the micro-app owns the first level, if the first level is shared, please have below in the 2nd level or whatever level it owns
      contentClassName: 'example-root panwds-tw3', // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
      component: () => import('./Routes'), // any component must be dynamically import like this
    },
  ],
};

export default function init() {
  return async function tsgLoad() {
    if (checkMfePSIfEnabled() || checkHasLicense()) {
      return appSecConfigNav;
    }
    return null;
  };
}
