import { getAuthState } from '@sparky/framework';
import { TsgInstances } from '@sparky/framework/build/types/framework/indexedArrays';

const MFE_PROPERTY_NAME = 'tenant_appsec_feature_flag_sre';

// check MFE property store to check if the tenant has the feature enabled
export function checkMfePSIfEnabled(): boolean {
  const authState = getAuthState();
  const authStateProperties = authState?.properties;
  const appEnabledProp = authStateProperties?.get(MFE_PROPERTY_NAME);
  return !!appEnabledProp?.enabled;
}

const APP_SEC_ID = 'add_app_sec' as const;

type EntitlementType = {
  app_id: 'add_app_sec' | string;
  purchased_size: number;
  product_type: 'PA-ADD' | string;
  license_type: 'MUACCESSAPPSECPAE' | string;
  license_expiration: string;
  form_factor_type: 'prisma_access' | string;
  license_display_name: 'The App Security add-on for MU' | string;
  purchased_unit: 'units' | string;
  license_term: 36 | number;
  license_unit: 'month' | string;
};

export function checkHasLicense(): boolean {
  return !!getLicense();
}

export function getLicense() {
  if (getLicense.license === undefined) {
    const authState = getAuthState();
    const instances: Readonly<TsgInstances> = authState?.instances || [];

    for (const inst of instances) {
      const appId = inst?.app_id;
      const allowedApps = new Set([
        'prisma_access_edition',
        'prisma_access_edition_onprem',
        'prisma_access_panorama',
      ]);

      // only check license for prisma access app_ids
      if (!allowedApps.has(appId)) {
        continue;
      }

      const entitlements: EntitlementType[] = instances?.get(appId)?.entitlements || [];

      for (const lic of entitlements) {
        if (lic.app_id === APP_SEC_ID) {
          getLicense.license = lic;
        }
      }
    }
  }
  return getLicense.license;
}

getLicense.license = undefined as EntitlementType | undefined;

const GRACE_PERIOD = 30; // days

export function checkIfLicenseExpired() {
  const license = getLicense();
  if (license && license.license_expiration) {
    const gracePeriodDate = getGracePeriod(license.license_expiration);
    return gracePeriodDate < new Date();
  }
  return false;
}

export const checkIfInGrace = (): boolean => {
  const license = getLicense();

  if (license && license.license_expiration) {
    const expirationDate = new Date(license.license_expiration);
    const gracePeriodDate = getGracePeriod(license.license_expiration);
    const currentDate = new Date();
    return currentDate > expirationDate && currentDate <= gracePeriodDate;
  }
  return false;
};

function getGracePeriod(licenseExpiration: string) {
  const expirationDate = new Date(licenseExpiration);
  const gracePeriodDate = new Date(expirationDate.getTime() + GRACE_PERIOD * 24 * 60 * 60 * 1000);
  return gracePeriodDate;
}
